<template>
  <v-autocomplete
    :items="options"
    :label="i18n(label)"
    :outlined="outlined"
    :readonly="readonly"
    :clearable="clearable"
    :prepend-inner-icon="icon"
    :hide-details="hideDetails"
    v-model="review"
  />
</template>

<script>
const onlyNumber = (str) => isNaN(str) ? null : Number(str)

export default {
  props: {
    value: {},
    icon: {},
    label: { default: 'field.review' },
    outlined: { default: false },
    readonly: { default: false },
    clearable: { default: false },
    hideDetails: { default: true }
  },

  data () {
    return {
      review: onlyNumber(this.value || 0),

      options: [
        { value: 0, text: 'Aguardando Revisão' },
        { value: 4, text: 'Parcialmente Revisado' },
        { value: 3, text: 'Em Exame' },
        { value: 2, text: 'Aguardando Aprovação' },
        { value: 1, text: 'Aprovado' }
      ]
    }
  },

  watch: {
    review (val) {
      const review = onlyNumber(val)
      const changed = review !== onlyNumber(this.value)
      if (changed) this.$emit('input', review)
    },

    value (val) {
      const value = onlyNumber(val)
      const changed = value !== onlyNumber(this.review)
      if (changed) this.review = value
    }
  }
}
</script>
