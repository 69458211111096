<template>
  <v-flex xs12>
    <v-card
      flat
      outlined
      width="100%"
      style="border: 1px solid rgba(0, 0, 0, 0.2)"
    >
      <v-list-item class="px-2">
        <v-list-item-action class="mr-2">
          <v-btn
            v-if="Boolean(error) || percent === '100%'"
            small
            icon
            @click="$emit('on-remove')"
          >
            <v-icon color="red lighten-1">
              mdi-close-circle-outline
            </v-icon>
          </v-btn>

          <v-progress-circular
            v-else
            indeterminate
            rotate="0"
            size="33"
            color="light-blue"
          >
            {{ percent || '0%' }}
          </v-progress-circular>
        </v-list-item-action>

        <v-list-item-content>
          <Message
            type="error"
            icon="mdi-cloud-alert"
            :show="Boolean(error)"
            :message="error"
          />

          <v-layout
            wrap
            class="pb-3"
          >
            <v-flex
              xs12
              v-if="music && music.src"
              class="py-0 pr-0"
            >
              <aplayer
                :music="music"
              />
            </v-flex>

            <v-flex xs12>
              <FieldStr
                label="Assunto"
                v-model="audio.assunto"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
            >
              <FieldGrau
                label="Grau do conteúdo"
                v-model="audio.grau"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
            >
              <FieldTipo
                label="Tipo do Áudio"
                v-model="audio.tipo"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
            >
              <FieldDate
                label="Data"
                v-model="audio.data"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
            >
              <FieldOrador
                clearable
                label="Orador"
                v-model="audio.orador"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
            >
              <FieldOrador
                clearable
                label="Dirigente"
                v-model="audio.dirigente"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
            >
              <FieldOrador
                clearable
                label="Autor"
                v-model="audio.autor"
              />
            </v-flex>

            <v-flex
              xs12
              sm8
            >
              <FieldCore
                icon=""
                clearable
                hide-details
                label="Núcleo"
                v-model="audio.nucleo"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
            >
              <FieldSessao
                label="Sessão"
                v-model="audio.sessao"
              />
            </v-flex>

            <v-flex
              xs12
              sm8
            >
              <FieldWords
                label="Palavras Chaves"
                v-model="audio.palavras"
                :rows="1"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
            >
              <FieldQualidade
                label="Qualidade"
                v-model="audio.qualidade"
              />
            </v-flex>

            <v-flex
              xs12
              sm8
            >
              <FieldAlbum
                clearable
                label="Álbum"
                v-model="audio.album"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
            >
              <FieldPermissao
                clearable
                label="Permissão"
                v-model="audio.permissao"
              />
            </v-flex>

            <v-flex
              xs12
              sm8
            >
              <FieldText
                label="Transcrição"
                v-model="audio.transcricao"
                :rows="1"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
            >
              <FieldReview
                label="Revisão"
                v-model="audio.review"
              />
            </v-flex>

            <v-flex xs12>
              <FieldText
                label="Mestre disse"
                v-model="audio.mestreDisse"
                :rows="1"
              />
            </v-flex>
          </v-layout>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-flex>
</template>

<script>

import FieldStr from '@/components/fields/FieldStr'
import FieldText from '@/components/fields/FieldText'
import FieldTipo from '@/components/fields/FieldTipo'
import FieldDate from '@/components/fields/FieldDate'
import FieldGrau from '@/components/fields/FieldGrau'
import FieldCore from '@/components/fields/FieldCore'
import FieldWords from '@/components/fields/FieldWords'
import FieldAlbum from '@/components/fields/FieldAlbum'
import FieldSessao from '@/components/fields/FieldSessao'
import FieldOrador from '@/components/fields/FieldOrador'
import FieldReview from '@/components/fields/FieldReview'
import FieldPermissao from '@/components/fields/FieldPermissao'
import FieldQualidade from '@/components/fields/FieldQualidade'
import Message from '@/components/Message'
import aplayer from 'vue-aplayer'

export default {
  components: {
    aplayer,
    Message,
    FieldStr,
    FieldText,
    FieldTipo,
    FieldDate,
    FieldGrau,
    FieldCore,
    FieldWords,
    FieldAlbum,
    FieldReview,
    FieldOrador,
    FieldSessao,
    FieldPermissao,
    FieldQualidade
  },

  props: {
    preparing: {},
    percent: {},
    value: {},
    error: {},
    stream: {}
  },

  data () {
    return {
      audio: { ...this.value }
    }
  },

  computed: {
    music () {
      if (!this.stream?.url) return null
      const { artist, name: title, url: src } = this.stream
      return { src, title, artist }
    }
  },

  watch: {
    value (value) {
      if (JSON.stringify(value) === JSON.stringify(this.audio)) return
      this.audio = { ...value }
    },

    audio: {
      deep: true,
      handler (audio) {
        if (JSON.stringify(audio) === JSON.stringify(this.value)) return
        this.$emit('input', { ...audio })
      }
    }
  }
}

</script>
